import Landing1 from "../landers/landing1";
import Landing2 from "../landers/landing2";
import Landing3 from "../landers/landing3";
import Landing4 from "../landers/landing4";
import PreLander1 from "../prelanders/preLander1";
import PreLander2 from "../prelanders/preLander2";
import { ROUTES } from "./routes";

export const TRACK_SCRIPT = [
    {
        pageUrl: ROUTES.landing1,
        content: <Landing1 />,
    },
    {
        pageUrl: ROUTES.landing2,
        content: <Landing2 />,
    },
    {
        pageUrl: ROUTES.landing3,
        content: <Landing3 />,
    },
    {
        pageUrl: ROUTES.landing4,
        content: <Landing4 />,
    },
    {
        pageUrl: ROUTES.preLander1,
        content: <PreLander1 />,
    },
    {
        pageUrl: ROUTES.preLander2,
        content: <PreLander2 />,
    },
]