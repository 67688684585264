import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { TRACK_SCRIPT } from "../constant/track";
import LandingPage from "./LandingPage";

export default function LandingPageWrapper() {
  const [loading, setLoading] = useState(true);
  const [script, setScript] = useState();

  const findScript = (pathName) => {
    const result = TRACK_SCRIPT.find((i) => i.pageUrl == pathName);
    if(result){
      setScript(result);
      setLoading(false);
    } else {
      window.location.href = "https://qualifyaca.com/"
    }
  };

  useEffect(() => {
    if (window.location.pathname) findScript(window.location.pathname);
  }, [window.location.pathname]);

  if (!window.location.pathname || loading || !script) return <></>;
  return (
    <>
      <LandingPage Content={script.content} scriptPath={script.scriptUrl} />
    </>
  );
}