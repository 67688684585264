import { BrowserRouter } from "react-router-dom";
import "./App.scss";
import Main from "./main";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useEffect } from "react";
import FloatingCard from "./components/FloatingCard/FloatingCard";
import { sessionStorageKeys, localStorageKeys } from "./constant/localStorage";
import { v4 as uuid } from "uuid";

function App() {
  const checkOrCreateVisitorId = () => {
    const localStorageVisitorId = localStorage.getItem(
      localStorageKeys.visitorId
    );
    if (localStorageVisitorId) {
    } else {
      const newVisitorId = uuid();
      localStorage.setItem(localStorageKeys.visitorId, newVisitorId);
    }
  };

  useEffect(() => {
    checkOrCreateVisitorId();
  }, []);

  if(window && window.document){
    window.document.title = "Grocery Allowance Card"
  }
  if (sessionStorage.getItem(sessionStorageKeys.isClickIdStored)) {
    sessionStorage.removeItem(sessionStorageKeys.isClickIdStored);
    var Cookies = document.cookie.split(";");
    for (var i = 0; i < Cookies.length; i++) {
      document.cookie = Cookies[i] + "=; expires=" + new Date(0).toUTCString();
    }

    const localLead = localStorage.getItem(
      localStorageKeys.leadLocalStorageItem
    );
    const visitorId = localStorage.getItem(localStorageKeys.visitorId);
    console.log(localStorage);
    localStorage.clear();
    console.log(localStorage);
    localStorage.setItem(localStorageKeys.leadLocalStorageItem, localLead);
    localStorage.setItem(localStorageKeys.visitorId, visitorId);
  }
  return (
    <HelmetProvider>
      <BrowserRouter>
        <Main />
        <FloatingCard />
      </BrowserRouter>
    </HelmetProvider>
  );
}

export default App;
