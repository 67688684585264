import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useGeneratorQuery } from "../hooks/useGeneratorQuery";
import { CLICK_ID } from "../hooks/rgba";
import Cookies from "js-cookie";
import { useRgbaHook } from "../hooks/rgba";
import { RINGBA_CLICK_ID } from "../constant/ringba";
import { sessionStorageKeys, localStorageKeys } from "../constant/localStorage";
import axios from "axios"
import { useEventID } from '../hooks/useEventID'

export default function LandingPage({ Content, scriptPath }) {
  const [search] = useSearchParams();
  const generatorQuery = useGeneratorQuery();
  const { storeRgbaData } = useRgbaHook();
  const [clickId, setClickId] = useState();
  const eventId = useEventID();

  const fbc = Cookies.get("_fbc" || "");
  const fbp = Cookies.get("_fbp" || "");

  const storeQueries = () => {
    for (const entry of search.entries()) {
      generatorQuery.set(entry[0], entry[1]);
    }

    storeRgbaData(
      "visitor_id",
      localStorage.getItem(localStorageKeys.visitorId)
    );
    storeRgbaData(
      "domainName",
      process.env.REACT_APP_DOMAIN_NAME.toLowerCase()
    );
    storeRgbaData("user_agent", window.navigator.userAgent);
    storeRgbaData("fbc", fbc);
    storeRgbaData("fbp", fbp);
    storeRgbaData("fbPixelId", window.fbPixelId);
    storeRgbaData('event_id', eventId)

    Cookies.set("visitor_id", localStorage.getItem(localStorageKeys.visitorId));
    Cookies.set(
      "visitor_id",
      localStorage.getItem(localStorageKeys.visitorId),
      { domain: process.env.REACT_APP_DOMAIN_NAME.toLowerCase() }
    );
    Cookies.set("acc_id", search.get("acc_id"));
    Cookies.set("acc_id", search.get("acc_id"), {
      domain: process.env.REACT_APP_DOMAIN_NAME.toLowerCase(),
    });
    Cookies.set("gclid", search.get("gclid"));
    Cookies.set("gclid", search.get("gclid"), {
      domain: process.env.REACT_APP_DOMAIN_NAME.toLowerCase(),
    });

    Cookies.set("grbaid", search.get("grbaid"));
    Cookies.set("grbaid", search.get("grbaid"), {
      domain: process.env.REACT_APP_DOMAIN_NAME.toLowerCase(),
    });

    Cookies.set("wbraid", search.get("wbraid"));
    Cookies.set("wbraid", search.get("wbraid"), {
      domain: process.env.REACT_APP_DOMAIN_NAME.toLowerCase(),
    });
  };

  const cityAddress = async () => {
    const options = {};
    const onSuccess = (success) => {
      const country = success.country
        ? success.country.names
          ? success.country.names.en
          : ""
        : "";
      const city = success.city
        ? success.city.names
          ? success.city.names.en
          : ""
        : "";
      const state = success.subdivisions
        ? success.subdivisions[0]
          ? success.subdivisions[0].iso_code
          : ""
        : "";
      storeRgbaData("city", city);
      storeRgbaData("state", state);
      storeRgbaData("zip", success.postal.code);
      
    };
    const onError = (error) => {
      console.log(error);
    };
    if (window.geoip2) await window.geoip2.city(onSuccess, onError, options);
  };

  const getIpAdd = async () => {
    let userIp;
    try {
      var response;
      response = await axios.get("https://api.ipify.org/?format=json", {
        method: "GET",
        mode: "no-cors",
        headers: {
          "Content-Type": "application/json",
        },
      });
      userIp = response.data["ip"];
    } catch (error) {
      console.error("IpError" + error);
    }
    storeRgbaData('userIp', userIp)
  };

  const addScript = async () => {};

  useEffect(() => {
    addScript();
    storeQueries();
  }, [search.get("generator")]);

  useEffect(() => {
    if (clickId) {
      storeRgbaData(RINGBA_CLICK_ID, clickId);
      Cookies.set(RINGBA_CLICK_ID, clickId, {
        domain: process.env.REACT_APP_DOMAIN_NAME.toLowerCase(),
      });
      sessionStorage.setItem(sessionStorageKeys.isClickIdStored, "true");
    }
  }, [clickId]);

  useEffect(() => {
    cityAddress();
    getIpAdd();
  }, []);

  return (
    <>
      {!clickId ? (
        <GetClickId clickId={clickId} setClickId={setClickId} />
      ) : undefined}
      {Content}
    </>
  );
}

function GetClickId(props) {
  React.useEffect(() => {
    if (!props.clickId) {
      const interval = setInterval(() => {
        props.setClickId(window.clickId);
      }, 400);
      return () => clearInterval(interval);
    }
  }, []);
  return <></>;
}
