export const ROUTES = {
    landing1: "/lander-1",
    landing2: "/lander-2",
    landing3: "/lander-3",
    landing4: "/lander-4",
    preLander1: "/prelander-1",
    preLander2: "/prelander-2",

    privacyPolicy: "https://quotes.qualifybenefits.co/privacy-policy",
    partner: "https://quotes.qualifybenefits.co/partner-list",
    termsAndConditions: "https://quotes.qualifybenefits.co/terms"
}

export default ROUTES