import React, { useEffect, useState } from "react";
import "../prelander.scss";
import { Rating } from "./Ratings/Ratings";
import { useNavigate, useSearchParams } from "react-router-dom";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import { useInitRingba } from "../../hooks/rgba";
import { useRgbaHook } from "../../hooks/rgba";
import { REDIRECT_AND_STORAGE_KEYS } from "../../constant/queryStrings";
import FloatingCard from "../../components/FloatingCard/FloatingCard";
import { localStorageKeys } from "../../constant/localStorage";
import Cookies from "js-cookie";

const image1 = "/assets/img/logos/united-healthcare.svg";
const image2 = "/assets/img/logos/anthem-bluecross.svg";
const image3 = "/assets/img/logos/aetna.svg";
const image4 = "/assets/img/logos/humana.svg";
const image5 = "/assets/img/logos/cigna.svg";
const image6 = "/assets/img/logos/kaiser-permanente.svg";

const PreLander2 = ({ setForm }) => {
  let nav = useNavigate();
  const { number, staticNumber } = useInitRingba();
  const { storeRgbaData } = useRgbaHook();
  const [search] = useSearchParams();

  const [cityStateResponse, setCityStateResponse] = useState({
    city: "",
    state: "",
  });

  const cityAddress = async () => {
    const options = {};
    const onSuccess = (success) => {
      const country = success.country
        ? success.country.names
          ? success.country.names.en
          : ""
        : "";
      const city = success.city
        ? success.city.names
          ? success.city.names.en
          : ""
        : "";
      const state = success.subdivisions
        ? success.subdivisions[0]
          ? success.subdivisions[0].names.en
          : ""
        : "";
      setCityStateResponse({ state, city, country });
    };
    const onError = (error) => {
      console.log(error);
    };
    if (window.geoip2) await window.geoip2.city(onSuccess, onError, options);
  };

  const init = () => {
    REDIRECT_AND_STORAGE_KEYS.forEach((obj) => {
      storeRgbaData(obj.ringbaKey, search.get(obj.redirectString));
    });
    storeRgbaData(
      "visitor_id",
      localStorage.getItem(localStorageKeys.visitorId)
    );

    Cookies.set("visitor_id", localStorage.getItem(localStorageKeys.visitorId));
    Cookies.set(
      "visitor_id",
      localStorage.getItem(localStorageKeys.visitorId),
      { domain: process.env.REACT_APP_DOMAIN_NAME.toLowerCase() }
    );
  };

  useEffect(() => {
    init();
    cityAddress();
  }, []);

  return (
    <div className="main">
      <Navbar tollFreehidden={true} number={number} staticNumber={staticNumber} />
      <div className="pre-form">
        <div className="pre-form-holder">
          <div className="pre-form-container">
            <div className="bold font-40 color-primary headline">
              New Medicare Benefits Update
            </div>

            <div className="font-24 color-primary sub-headline">
              Americans in
              {" " + cityStateResponse["city"]
                ? " " + cityStateResponse["city"] + ","
                : ""}
              {cityStateResponse["state"] + " "}Ages 65+ In Have 10 days to
              claim additional benefits & get up to $240 monthly in OTC
              benefits.
            </div>

            <div className="color-dark-grey paragraph">
              Thanks to a new Medicare Advantage package, Americans age 65+
              could get $148.50* monthly added to their social security check
              with No-Cost Vision & Dental Benefits.
            </div>
            <a href="#" className="qualify-button">
              See If You Qualify
            </a>
          </div>
          <div className="image-section carrier-images">
            <img src={image1} alt="rating section" />
            <img src={image2} alt="rating section" />
            <img src={image3} alt="rating section" />
            <img src={image4} alt="rating section" />
            <img src={image5} alt="rating section" />
            <img src={image6} alt="rating section" />
          </div>
        </div>

        <FloatingCard />
      </div>

      <FormStart />

      <Footer number={number} />
    </div>
  );
};

const FormStart = () => {
  return (
    <div className="form-start">
      <Rating />
    </div>
  );
};

export default PreLander2;
