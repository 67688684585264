import React from "react";
import { useEventID } from "../hooks/useEventID";
import { useSearchParams } from "react-router-dom";

const PHONE_IMAGE = "/assets/images/phone.svg";
const LOGO_IMAGE = "/assets/images/logo.png";

export default function Navbar({ number, tollFreehidden }) {
  const eventID = useEventID();
  const [search] = useSearchParams();
  const handleCallEventClick = () => {
    if (search.get("utm_source") == "facebook") {
      window.fbcFunc &&
        window.fbcFunc("track", "Contact", {
          eventID: eventID,
        });
    }
  };
  return (
    <>
      <div className="navbar">
        <nav>
          <div className="logoholder">
            <div className="logo">
              <img src={LOGO_IMAGE} alt="logo" />
              <div className="sitename">
                <h1>{process.env.REACT_APP_DOMAIN_NAME}</h1>
                <h2>A non-government medicare site</h2>
              </div>
            </div>
          </div>
          {!tollFreehidden ? (
            <a
              href={`tel:${number}`}
              onClick={handleCallEventClick}
              className="callnow"
              id="prelander_call"
            >
              <div className="phone-icon">
                <img src={PHONE_IMAGE} alt="logo" />
              </div>
              <div className="phone-number-holder">
                <div className="text-phone-number">
                  CALL TOLL-FREE M-F 8AM-8PM EST
                </div>
                <div className="toll-free-number-holder">
                  <div className="available-blinker"></div>
                  <div className="toll-free-number">
                    <span id="font-end-contact-number" className="font-bold">
                      <span className="display-number">{number}</span>
                    </span>
                    <span className="font-semibold">(TTY 711)</span>
                  </div>
                </div>
              </div>
            </a>
          ) : undefined}
        </nav>
      </div>
    </>
  );
}
