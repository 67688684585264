import { useEffect, useState } from "react";

export const GENERATOR_QUERY = "generator";
export const RINGBA_SCRIPT_ID = "rgba_script";
export const RINGBA_CLICK_ID = "vl_click_id";

export const RINGBA_COM_TAGS = [
  {
    key: "JSddda290a6982485aa73926e3b1bf3728",
    user: "internal",
    number: "1-877-556-1496",
  },
  //{
  //key: 'JS8d271f1ea8034bda8e8c7f24e346e5cb',
  //user: 'pranavtest',
  //number: '1-866-578-2331',
  //}
];

const replaceExtraString = (str) => {
  try {
    const arr = str.split("&");
    const generator = arr.find((a) => a.includes("generator="));
    if (generator) {
      str = generator;
      if (str.includes("?generator=")) str = str.replace("?generator=", "");
      if (str.includes("generator=")) str = str.replace("generator=", "");
    }
  } catch (error) {}
  return str;
};

export function useRingbaUser() {
  const activeUserKeyObj = () =>
    RINGBA_COM_TAGS.find((i) => {
      return i.user === replaceExtraString(window.location.search);
    });

  if (activeUserKeyObj()) return activeUserKeyObj();
  else return RINGBA_COM_TAGS[0];
}
