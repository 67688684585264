export const localStorageKeys = {
  visitorId: "visitorId",
  leadLocalStorageItem: "LeadiD-request-catalog",
};

export const sessionStorageKeys = {
  isClickIdStored: "isClickIdStored",
  rgba: "rgba",
  generatorName: "generatorName", // remove at end
  generator: "generator",

  clickId: "vl_click_Id",
  eventID: "eventID",
};
